import request from "@/utils/request";
import {APICONFIG} from "@/utils/apiConfig";


/**
 * 根据分类id查询 AI应用
 */
export function findCardByCatId(id) {
    return request({
        url: `${APICONFIG}/app/center/${id}`,
        method: 'get',
    })
}


export function findCardByType(type) {
    return request({
        url: `${APICONFIG}/app/center/by_type/${type}`,
        method: 'get',
    })
}

