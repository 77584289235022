<template>
  <a-spin :spinning="spanning" class="main-spinning" size="large">
    <CreateCard :cards="catArr" @cardClick="cardClick" v-if="!showChatPanel"/>
    <!--    </a-spin>-->
    <div class="chat-panel-container" v-if="showChatPanel">
      <BackTitle :title="title" @backClick="backClick" class="title"/>
      <ChatPanel :chatData="chatData" class="chat-panel" @clickLink="clickLink"/>
      <SendTextContent :showOperation="false" @sendMessage="sendMessage"/>
    </div>
  </a-spin>
</template>

<script>
import {initContent, initWork} from "@/api/center";
import {currentTime} from "@/utils/DateUtil";
import {generateUUID} from "ant-design-vue/lib/vc-select/util";
import {EventSourcePolyfill} from "event-source-polyfill";
import {APICONFIG} from "@/utils/apiConfig";
import {chat} from "@/api/chat";
import {findCardByCatId, findCardByType} from "@/api/v2/appcenter";

const defaultMessage = `<div><a-icon type="sync" spin />AI思考中... </div>`
export default ({
  components: {
    CreateCard: () => import('@/views/application/chat/components/CreateCard.vue'),
    BackTitle: () => import("@/components/Application/BackTitle.vue"),
    ChatPanel: () => import("@/views/application/chat/components/ChatPanel.vue"),
    SendTextContent: () => import("@/views/application/chat/components/SendTextContent.vue")
  },
  data() {
    return {
      showChatPanel: false,
      catArr: [],
      curCard: {},
      title: '',
      chatData: [],
      chatUid: '',
      model: 'gpt-3.5-turbo',
      spanning: false
    }
  },
  watch: {
    '$route.params.id': {
      handler(current) {
        if (current) {
          this.initById(current)
        } else {
          this.init()
        }
      }
    }
  },
  methods: {
    initById(id) {
      this.spanning = true
      findCardByCatId(id).then(res => {
        this.spanning = false
        let arr = []
        arr.push({
          cat_name: this.$route.params.name,
          apps: res.data
        })
        this.catArr = arr
      }).finally(() => {
        this.spanning = false
      })
    },
    init() {
      this.spanning = true
      findCardByType('chat').then(res => {
        this.spanning = false
        this.catArr = res.data
      }).finally(() => {
        this.spanning = false
      })
    },
    clickLink(text) {
      this.chatUid = generateUUID()
      this.chatData.push({
        right: {
          dateTime: currentTime(),
          text
        }
      })
      this.createSse()
      chat(this.chatUid, text + "（要求：回复你F内容以markdown方式输出）", this.model).then(res => {
        if (res && res.code === -1) {
          this.chatData.push({
            left: {
              text: res.message,
              dateTime: currentTime()
            }
          })
        } else {
          this.chatData.push({
            left: {
              text: defaultMessage,
              dateTime: currentTime()
            }
          })
        }
      }).finally(() => {
      })
    },
    getServeRes(text) {
      if (this.chatData) {
        let cur = this.chatData[this.chatData.length - 1]
        //当前批次存在服务端返回消息，流式
        if (cur.left) {
          let resData = cur.left.text.indexOf(defaultMessage) >= 0 ? text : cur.left.text + text
          return {
            dateTime: currentTime(),
            text: resData,
          }
        } else {
          return {
            dateTime: currentTime(),
            text,
          }
        }
      }
    },
    sendMessage(text, model) {
      this.model = model
      this.chatData.push({
        right: {
          text,
          dateTime: currentTime()
        }
      })
      //已有对话
      if (!this.chatUid) {
        this.chatUid = generateUUID()
      }
      this.createSse()
      chat(this.chatUid, text + "要求：回复你内容以markdown方式输出）", this.model).then(res => {
        if (res && res.code === -1) {
          this.chatData.push({
            left: {
              text: res.message,
              dateTime: currentTime()
            }
          })
        } else {
          this.chatData.push({
            left: {
              text: defaultMessage,
              dateTime: currentTime()
            }
          })
        }
      }).finally(() => {
      })
    },
    createSse() {
      if (window.EventSource) {
        const cc = localStorage.getItem("cc")
        if (!cc) {
          localStorage.setItem("cc", generateUUID())
        }
        const eventSource = new EventSourcePolyfill(`${APICONFIG}/createSse`, {
          headers: {
            uid: this.chatUid,
            'X-model-name' : this.model,
            'X-consume-num': 1,
            'X-client-code': localStorage.getItem("cc"),
            'X-Token': this.$store.getters.token
          }
        });
        eventSource.onmessage = (event) => {
          if (event.lastEventId === "[TOKENS]") {
          }
          if (event.data === "[DONE]") {
            if (this.sse) {
              this.sse.close();
            }
            return;
          }
          let json_data = JSON.parse(event.data)
          if (json_data.content == null || json_data.content === 'null') {
            return;
          }
          const res = this.getServeRes(json_data.content)
          if (res) {
            let cur = this.chatData[this.chatData.length - 1]
            this.$set(cur, "left", res)
          }
          this.scrollToBottom()
        }
        eventSource.onopen = (event) => {
          this.sse = event.target;
        };
        eventSource.onerror = (event) => {
          if (this.sse) {
            this.sse.close();
          }
        };
        eventSource.close = (event) => {
          console.log("close :" + 44 + ": ", event)
        };
      }
    },

    //设置滚动条到底部
    scrollToBottom() {
      this.$nextTick(() => {
        let container = this.$el.querySelector(".chat-panel");
        container.scrollTop = container.scrollHeight;
      });
    },
    cardClick(card, catInfo) {
      this.title = catInfo.cat_name
      this.showChatPanel = true
      this.chatData = []
      const regex = /- #([^#]+)#/g
      const r1 = /[^#]#([^#]+)#/g
      let replacedStr = card.userMsg
          .replace(regex, ' <div class="link-text">$1</div>')
          .replace(r1, ' <div class="link-text">$1</div>');
      this.chatData.push({
        left: {
          dateTime: currentTime(),
          text: replacedStr
        }
      })
    },
    backClick() {
      this.showChatPanel = false
      this.chatData = []
      this.chatUid = ''
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.initById(this.$route.params.id)
    } else {
      this.init()
    }
  }
})
</script>

<style scoped lang="scss">

.main-spinning {
  height: calc(100vh - 60px);
}

::v-deep.ant-spin-nested-loading > div > .ant-spin {
  max-height: 600px;
}

.chat-panel-container {
  //padding: 15px;
  .title {
    margin: 15px 15px 0 15px;
  }

  .chat-panel {
    height: calc(100vh - 220px);
    overflow-y: auto;
  }
}
</style>