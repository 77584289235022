import request from "@/utils/request";
import {APICONFIG} from "@/utils/apiConfig";


/**
 * 查询APP字典接口
 */
export function list() {
    return request({
        url: `${APICONFIG}/dict/all`,
        method: 'get',
    })
}


/**
 * 查询APP字典接口
 */
export function init() {
    return request({
        url: `${APICONFIG}/dict/init/plus`,
        method: 'get',
    })
}

/**
 * 查询APP字典接口
 */
export function initAI() {
    return request({
        url: `${APICONFIG}/dict/init/ai`,
        method: 'get',
    })
}

export function initContent(id) {
    return request({
        url: `${APICONFIG}/dict/init/ai/${id}`,
        method: 'get',
    })
}

export function initWork(type) {
    return request({
        url: `${APICONFIG}/dict/init/work/${type}`,
        method: 'get',
    })
}


/**
 * 查询APP字典接口
 */
export function chat(req) {
    return request({
        url: `${APICONFIG}/app/center?req=${req}`,
        method: 'get',
    })
}


/**
 * 查询APP字典接口
 */
export function findCollection() {
    return request({
        url: `${APICONFIG}/find/collection`,
        method: 'get',
    })
}

/**
 * 查询APP字典接口
 */
export function addCollection(appid) {
    return request({
        url: `${APICONFIG}/add/collection/${appid}`,
        method: 'get',
    })
}

/**
 * 查询APP字典接口
 */
export function removeCollection(appid) {
    return request({
        url: `${APICONFIG}/remove/collection/${appid}`,
        method: 'get',
    })
}

// /**
//  * 短信验证
//  */
// export function login(data) {
//     return request({
//         url: `${APICONFIG}/user/login`,
//         method: 'post',
//         data
//     })
// }


